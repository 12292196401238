import * as React from "react"
import { Link } from "gatsby"

import { getUserFeedback, addUserFeedback, updateUserFeedback } from "../utils/customApiCall"

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea, getNativeSelectUtilityClasses } from '@mui/material';
import IconButton from '@mui/material/IconButton'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// class ComingSoonPreview extends React.Component {
//     constructor(props) {
//       super(props);
//       this.handleChange = this.handleChange.bind(this);
//     }
  
//     handleChange(votes) {
//       this.props.onVoteChange(votes);
//     }
  
//     render() {
//       const temperature = this.props.temperature;
//       const scale = this.props.scale;
//       return (
//         <fieldset>
//           <legend>Enter temperature in {scaleNames[scale]}:</legend>
//           <input value={temperature}
//                  onChange={this.handleChange} />
//         </fieldset>
//       );
//     }
//   }

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ComingSoonPreview = (props) => {
    const initialDialogTitle = "Please log in to vote!"
    const initialDialogDetails = "Click log-in and you will be forwarded to an Azure AD B2C Login page where you can sign-up or log-in with either an email, Facebook, Google account!"
    const [dialogTitle, setDialogTitle] = React.useState(initialDialogTitle);
    const [dialogDetails, setDialogDetails] = React.useState(initialDialogDetails);
    const [open, setOpen] = React.useState(false);
    const [vote, setVote] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleChange(votes) {
        props.onVoteChange(votes);
    }

    let loggedIn = props.userDetails ? true : false
    const accessToken = props.accessToken
    const post = props.post
    const title = post.frontmatter.title || post.fields.slug
    
    const redirectToLogin = () => {
        props.instance.loginRedirect();
    }

    const removeVote = () => {
        var newVotes = props.votes.filter(function(value){ 
            return value !== post.fields.slug;
        })
        var updatedFeedback = {
            PartitionKey: props.userDetails.username,
            Id: props.userDetails.username,
            Name: props.userDetails.username,
            Status: "Updated",
            Votes: newVotes
        }
        updateUserFeedback(accessToken, JSON.stringify(updatedFeedback))
        handleChange(newVotes)
        setOpen(false)
        setVote("Remove")
    }

    function voteClicked () {
        setVote(null)
        if (loggedIn) {
            if (props.votes.includes(post.fields.slug)) {
                // Open confirmation modal to remove vote
                setDialogTitle("Are you sure you want to remove your vote?")
                setDialogDetails("Vote counts are updated nightly")
            } else {
                // Add vote
                setDialogTitle("Thank you for voting!")
                setDialogDetails("Vote counts are updated nightly")

                setVote("Add")

                if (props.votes) {
                    // Update user feedback row
                    var _votes = props.votes
                    _votes.push(post.fields.slug)
        
                    var updatedFeedback = {
                        PartitionKey: props.userDetails.username,
                        Id: props.userDetails.username,
                        Name: props.userDetails.username,
                        Status: "Updated",
                        Votes: _votes
                    }
        
                    updateUserFeedback(accessToken, JSON.stringify(updatedFeedback))

                    handleChange(_votes)
                } else {
                    // Create user feedback row
                    var newFeedback = {
                        PartitionKey: props.userDetails.username,
                        RowKey: props.userDetails.username,
                        Name: props.userDetails.username,
                        Status: "New",
                        Votes: post.fields.slug,
                        ChangeCount: 1
                    }
        
                    addUserFeedback(accessToken, JSON.stringify(newFeedback))

                    handleChange([post.fields.slug])
                }
            }
        } else {
            // Open login required modal
            setDialogTitle(initialDialogTitle)
            setDialogDetails(initialDialogDetails)
        }

        handleClickOpen()
    }

    return (<>
        <Card sx={{ maxWidth: 345, marginBottom: "25px", height: "fit-content" }} >
            {/* <Link to={post.fields.slug} style={{ textDecoration: "none", color: "inherit" }}> */}
                {/* <CardActionArea> */}
                    <CardMedia
                        component="img"
                        height="140"
                        image={post.frontmatter.previewImage ? post.frontmatter.previewImage : "https://cdn.sanity.io/images/5ho08nfv/production/26310230bf276b6456ba36e2e232a9c7ae154b8e-1350x900.png?rect=60,470,610,343&w=1200&h=675&fit=crop&auto=format"}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description || post.excerpt,
                        }}></Typography>
                    </CardContent>
                    <CardActions>
                        <IconButton aria-label="Vote for post" onClick={voteClicked}>
                            {
                                loggedIn && props.votes?.includes(post.fields.slug) ?
                                    <FavoriteOutlinedIcon color="primary" label="5 Votes" /> : <FavoriteOutlinedIcon color="white" label="5 Votes" />
                            }
                        </IconButton>
                            {post.frontmatter.votes ? post.frontmatter.votes : 0} Votes
                        <Chip label={post.frontmatter.date} variant="outlined" style={{ marginLeft: "auto", cursor: "inherit" }} />
                    </CardActions>
                {/* </CardActionArea> */}
            {/* </Link> */}
        </Card>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogDetails}
                </DialogContentText>
            </DialogContent>
            {
                !loggedIn ? <DialogActions>
                    <Button onClick={redirectToLogin} variant="contained" color="primary" autoFocus>
                        Login
                    </Button>
                </DialogActions> : !props.votes.includes(post.fields.slug) || vote === "Add" ? <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions> : <DialogActions>
                    <Button onClick={removeVote} variant="contained" color="primary">
                        Remove
                    </Button>
                </DialogActions>
            }
        </Dialog>
        </>
    );
};

export default ComingSoonPreview
