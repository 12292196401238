import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby"

import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { TableClient, AzureSASCredential } from '@azure/data-tables';

import Layout from "../components/layout"
import Seo from "../components/seo"
import ComingSoonPreview from "../components/comingSoonPreview"

import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";
import { getUserEmail } from "../utils/profileService"
import { getUserFeedback } from "../utils/customApiCall"

import { Fab } from "@mui/material"
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle'

class FeedbackSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleVoteChange = this.handleVoteChange.bind(this);
    this.state = {votes: JSON.parse(this.props.userFeedback).votes};
  }

  handleVoteChange(votes) {
    this.setState({votes: votes});
  }

  render() {
    const userFeedback = this.props.userFeedback
    const votes = this.state.votes

    return (
      <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}, marginTop: '25px' }}>
        {this.props.data.allMarkdownRemark.nodes.map(post => {
            return (
              <ComingSoonPreview key={post.fields.slug} post={post}
                userDetails={this.props.account} votes={votes} userFeedback={userFeedback}
                accessToken={this.props.accessToken} instance={this.props.instance}
                onVoteChange={this.handleVoteChange} />
            )
        })}
      </Box>
    );
  }
}

const ComingSoonPage = ({ data }) => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [userFeedback, setUserFeedback] = useState(null);
  const [accessToken, setAccessToken] = useState(null)

  const authorizedRequest = {
    ...apiRequest,
    account: account
  };

  instance.acquireTokenSilent(authorizedRequest).catch((e) => {
    if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(authorizedRequest);
    }
  });

  useEffect(() => {
    if (account && !userFeedback && inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(authorizedRequest).then((response) => {
          setAccessToken(response.accessToken)
          getUserFeedback(response.accessToken, getUserEmail()).then((feedback) => {
            setUserFeedback(feedback ? feedback : JSON.stringify({id: 'new'}))
          });
        }).catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(authorizedRequest);
            }
        });
    }
  }, [account, inProgress, instance, userFeedback]);

  return (
    <Layout>
      <Seo title="Coming Soon" />
      <Container maxWidth="xl">
        <Box sx={{ my: 4 }}>
          <Typography variant="h2" component="h2" gutterBottom>
              Coming Soon
          </Typography>
          <Typography variant="caption" gutterBottom>
              Last Updated: May 8th 2022
          </Typography>
          <AuthenticatedTemplate>
          {
            userFeedback ? <FeedbackSection userFeedback={userFeedback} data={data}
              account={account} instance={instance} accessToken={accessToken}  /> : null
          }
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
          {
            <FeedbackSection userFeedback={JSON.stringify({id: 'new'})} data={data}
              account={account} instance={instance} accessToken={accessToken}  />
          }
          </UnauthenticatedTemplate>
        </Box>
      </Container>
    </Layout>
  )
}

export default ComingSoonPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {eq: true}}}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          previewImage
          votes
        }
      }
    }
  }
`
